import { render, staticRenderFns } from "./appHeader.vue?vue&type=template&id=488c5ba3&scoped=true&"
import script from "./appHeader.vue?vue&type=script&lang=js&"
export * from "./appHeader.vue?vue&type=script&lang=js&"
import style0 from "./appHeader.vue?vue&type=style&index=0&id=488c5ba3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488c5ba3",
  null
  
)

component.options.__file = "appHeader.vue"
export default component.exports