import { render, staticRenderFns } from "./fvSidebar.vue?vue&type=template&id=2e547fb2&"
import script from "./fvSidebar.vue?vue&type=script&lang=js&"
export * from "./fvSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "fvSidebar.vue"
export default component.exports